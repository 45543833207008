/** @format */

import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "../../assets/style/Trusted.css";

const Trusted = () => {
  return (
    <>
      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Saubaru-N-logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/skoda-n-logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Renault.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Range-Logos.jpg"
              height="150"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/porche.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/peu-logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/land-n-logo.jpg"
              height="100"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Ford-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/chevrolet.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/BMW-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Mini-N.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/mercede.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Mazda-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/kia-n-logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Jeep-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Hyundai-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Honda-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Holden-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Ford-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Ford-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Ford-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Ford-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Ford-N-Logo.jpg"
              height="150"
              width="150"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Trusted;
