/** @format */
import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Grid,
  Box,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useCart } from "../components/CartContext";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
export default function Cart() {
  const { cartItems, removeFromCart, updateQuantity, addToCart } = useCart();
  const [couponCode, setCouponCode] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [shippingOption, setShippingOption] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  const navigate = useNavigate();
  const stripePromise = loadStripe(
    "pk_test_51Pw99QJ5eHYvRoN6BZd24jhznab2VpVrsxo0oG4dbHyzZGhK9OSC2j6ZhON9osWwlw5bpT2S0P1YeZQ2pI4KHY9a00JHQXOaAa"
  );

  useEffect(() => {
    // Sync cartItems with localStorage
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    // Set default quantity for existing items
    cartItems.forEach((item) => {
      if (item.quantity === undefined || item.quantity <= 0) {
        updateQuantity(item.id, 1); // Set default quantity if not set
      }
    });
  }, [cartItems]);

  const handleQuantityChange = (id, change) => {
    const item = cartItems.find((item) => item.id === id);
    const newQuantity = (item ? item.quantity : 0) + change;
    if (newQuantity > 0) {
      updateQuantity(id, newQuantity);
    }
  };

  const handleRemoveItem = (id) => removeFromCart(id);
  const handleCloseSnackbar = () => setSnackbarOpen(false);
  const handleShippingChange = (event) => setShippingOption(event.target.value);

  const handleCheckout = async () => {
    if (!shippingOption) {
      setPopupOpen(true);
    } else {
      try {
        setSnackbarMessage("Proceeding to checkout.");
        setSnackbarOpen(true);

        const stripe = await stripePromise;

        const response = await fetch(
          "http://localhost:5000/create-checkout-session",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: cartItems,
              shippingOption,
            }),
          }
        );

        const sessionId = await response.json();
        const { id } = sessionId;

        const result = await stripe.redirectToCheckout({ sessionId: id });
        if (result.error) {
          console.error(result.error.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const handleClosePopup = () => setPopupOpen(false);

  const subtotal = cartItems.reduce(
    (sum, item) =>
      sum + (Number(item.price) || 0) * (parseInt(item.quantity) || 0),
    0
  );

  const shippingOptionsText = {
    standardTracking: "AUS Standard Post (With Tracking) - $9.35",
    standardNOTracking: "AUS Standard Post (NO Tracking) - $7.90",
    express: "AUS Express Post (With Tracking) - $12.50",
  };

  const shippingOptions = {
    standardTracking: 9.5,
    standardNOTracking: 8,
    express: 12.5,
  };

  const shipping = shippingOptions[shippingOption] || 0;
  const total = subtotal + shipping;

  return (
    <Container maxWidth="lg" sx={{ mt: 9, mb: 14 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ color: "#000", fontSize: { xs: "1.5rem", md: "2rem" } }}>
        Shopping Cart
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#5271ff" }}>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    IMAGE
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    PRODUCT
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", color: "#fff" }}>
                    PRICE
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", color: "#fff" }}>
                    QUANTITY
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", color: "#fff" }}>
                    TOTAL
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="h6">Your cart is empty.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  cartItems.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <img
                          src={item.image}
                          alt={item.name}
                          style={{
                            width: "100%",
                            maxWidth: 80,
                            height: "auto",
                            borderRadius: 4,
                          }}
                        />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="right">
                        ${Number(item.price).toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleQuantityChange(item.id, -1)}
                          sx={{ color: "#5271ff" }}
                          disabled={item.quantity === 1}>
                          <RemoveIcon />
                        </IconButton>
                        <TextField
                          value={item.quantity}
                          onChange={(e) => {
                            const newQuantity = parseInt(e.target.value) || 1;
                            if (newQuantity > 0) {
                              updateQuantity(item.id, newQuantity);
                            }
                          }}
                          inputProps={{
                            min: 1,
                            style: { textAlign: "center" },
                          }}
                          sx={{ width: 60, mx: 1 }}
                        />
                        <IconButton
                          size="small"
                          onClick={() => handleQuantityChange(item.id, 1)}
                          sx={{ color: "#5271ff" }}>
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        ${(Number(item.price) * item.quantity).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleRemoveItem(item.id)}
                          sx={{ color: "#5271ff" }}>
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
              flexDirection: { xs: "column", sm: "row" },
              mt: 2,
            }}>
            <TextField
              label="Coupon Code"
              variant="outlined"
              size="small"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              sx={{ width: { xs: "100%", sm: "50%" }, mb: { xs: 2, sm: 0 } }}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#5271ff",
                "&:hover": { backgroundColor: "#5271ffee" },
              }}
              onClick={() => {
                // Handle coupon code logic here
                setSnackbarMessage("Coupon applied successfully!");
                setSnackbarOpen(true);
              }}>
              Apply Coupon
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              gap: 1,
              flexDirection: { xs: "column", sm: "row" },
            }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#5271ff",
                "&:hover": { backgroundColor: "#5271ffee" },
              }}
              onClick={() => {
                // Handle update cart logic here
                setSnackbarMessage("Cart updated successfully!");
                setSnackbarOpen(true);
              }}>
              Update Cart
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper sx={{ p: 2, boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#5271ff" }}>
              Cart Totals
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Subtotal</TableCell>
                  <TableCell align="right">${subtotal.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Shipping</TableCell>
                  <TableCell align="right">
                    <Select
                      value={shippingOption}
                      onChange={handleShippingChange}
                      variant="standard"
                      displayEmpty
                      renderValue={(value) =>
                        value ? shippingOptionsText[value] : "Select Shipping"
                      }>
                      <MenuItem value="standardTracking">
                        {shippingOptionsText.standardTracking}
                      </MenuItem>
                      <MenuItem value="standardNOTracking">
                        {shippingOptionsText.standardNOTracking}
                      </MenuItem>
                      <MenuItem value="express">
                        {shippingOptionsText.express}
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell align="right">${total.toFixed(2)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box
              sx={{
                mt: 2,
                textAlign: "right",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 1,
              }}>
              <Button
                disabled={cartItems.length === 0}
                variant="contained"
                onClick={handleCheckout}
                sx={{
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#5271ffee" },
                }}>
                Proceed to Checkout
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={popupOpen}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Select Shipping Option"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select a shipping option to proceed to checkout.
          </DialogContentText>
          <RadioGroup
            aria-label="shipping-options"
            value={shippingOption}
            onChange={handleShippingChange}>
            <FormControlLabel
              value="standardTracking"
              control={<Radio />}
              label="AUS Standard Post (With Tracking) - $9.35"
            />
            <FormControlLabel
              value="standardNOTracking"
              control={<Radio />}
              label="AUS Standard Post (NO Tracking) - $7.90"
            />
            <FormControlLabel
              value="express"
              control={<Radio />}
              label="AUS Express Post (With Tracking) - $12.50"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
